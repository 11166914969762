<template>
  <router-view></router-view>
</template>

<script>
import axios from '../../axios';

export default {
  name:'Drs',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    fetchDailyReturnSheets() {
      axios.get(`/dailyReturnSheets/fetch.json?token=${this.token}`)
        .then(() => {
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.fetchDailyReturnSheets();
  },
};
</script>
